




















import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import JobApplicantsTable from "@/components/jobApplicants/JobApplicantsTable.vue"
import {mapGetters,mapActions} from 'vuex'
export default Vue.extend({
  components: {
    Layout,
    JobApplicantsTable
  },
  created(){
    this.fetchJobApplicants()
  },
  methods:{
    ...mapActions("azJobApplicantsModule",{
      fetchJobApplicants:"FETCH_JOB_APPLICANTS",
    }),
    getSearchValue(val){

      this.searchText=val
    }
  },
  data() {
    return {
      title: "Job Applicants",
      searchText:'',
    };
  },
});
