<template>
  <div class="notifications-table">
      <b-row>
          <b-col cols="12" md="3" lg="3" class="mb-4 pl-0">
            <search-bar applicantSearch /> 
          </b-col>
          <b-col cols="12" md="7" lg="6" offset-md="2" offset-lg="3" class="mb-4 pl-0 d-flex align-items-center">
            <div class="mr-2 filter" @click="filterApplicants"><i class="bx bx-filter"></i></div>
            <b-form-input type="search" v-model="search.position" placeholder="Job title"></b-form-input>
            <b-form-select v-model="search.jobApplicantStatus" class="mx-2">
              <b-form-select-option :value="null">Status</b-form-select-option>
              <b-form-select-option :value="1">New</b-form-select-option>
              <b-form-select-option :value="2">In review</b-form-select-option>
              <b-form-select-option :value="3">Contacted</b-form-select-option>
              <b-form-select-option :value="4">Rejected</b-form-select-option>
              <b-form-select-option :value="5">Hiring Closed</b-form-select-option>
            </b-form-select>
            <b-form-input type="date" v-model="search.createdAt"></b-form-input>
          </b-col>
      </b-row>
    <b-table
      :responsive="true"
      :items="jobApplicants"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
    >
        <template v-slot:cell(linkedInUrl)="data">
            <b-badge class="px-2"
                v-if="data.item.linkedInUrl"
            >
              <a class="text-white" :href="data.item.linkedInUrl" target="_blank">Link <i class="bx bx-copy"></i></a>
            </b-badge>
            <span v-else>N/A</span>
        </template>
        <template v-slot:cell(jobApplicantStatus)="data">
            <b-badge pill :variant="getStatusColor(data.item.jobApplicantStatus).color">{{ getStatusColor(data.item.jobApplicantStatus).status }}</b-badge>
        </template>
        <template v-slot:cell(actions)="data">
            <i @click="edit(data.item.id)" class="bx bx-edit-alt"></i>
        </template>
    </b-table>
    <b-pagination
      class="d-flex justify-content-center mt-5"
      aria-controls="notifications-table"
      v-model="currentPage"
      :total-rows="jobApplicants.length"
      :per-page="perPage"
      first-number
      last-number
    ></b-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import SearchBar from "@/components/search-bar.vue";
export default {
  props: {
    searchText: {
      type: String,
    },
  },
  components: {
    "search-bar": SearchBar
  },

  computed:{
    ...mapGetters("azJobApplicantsModule", {
      jobApplicants: "GET_JOB_APPLICANTS"
    }),
    fields() {
      return [
        {
            key: 'createdAt',  
            label: this.$i18n.t('Date of receipt'),
            formatter: (value, key, item) => {
                    return item.createdAt ? this.getDate(item.createdAt) : 'N/A'
                },
        },
        {
            key: "position",
            label: this.$i18n.t('Position'),
        },
        {
            key: "name",
            label: this.$i18n.t('Name'),
        },
        {
            key: "surname",
            label: this.$i18n.t('Surname'),
        },
        {
            key: "linkedInUrl",
            label: this.$i18n.t('Linkedin URL'),
        },
        {
            key: "jobApplicantStatus",
            label: this.$i18n.t('Status'),
        },
        {
            key: "actions",
            label: "Action",
            thClass: 'text-right',
            tdClass: 'text-right',
        }
      ]
    },
    loc_notifications(){
      let temp_notification= this.notifications.map(item=>{
        return {
          ...item,
          languages: [
            {
              lang: 'English',
              translated: true,
              translation_changed: false,
            },
            {
              lang: 'Slovakia',
              translated: true,
              translation_changed: false,
            },
          ],
        }
      })
      if(this.searchText!=''){
        return temp_notification.filter(item=>item.clientZoneNotificationType.toString().includes(this.searchText.toLowerCase()))
      }
      else{
        return temp_notification
      }
    },

    // fields(){
    //   return [
    //     {
    //       key: this.$i18n.locale == "en" ? "clientZoneNotificationNameEn" : "clientZoneNotificationNameSk",
    //       label: this.$i18n.t('User Name'),
    //     },
    //     {
    //       key: "clientZoneNotificationImportance",
    //       label: this.$i18n.t('Role'),
    //     },
    //     {
    //       key: "clientZoneNotificationImportance",
    //       label: this.$i18n.t('Company'),
    //     },
    //     {
    //       key: "notificationIntervalInDays",
    //       label: this.$i18n.t('Activity'),
    //     },
    //     {
    //       key: "notificationIntervalInDays",
    //       label: this.$i18n.t('Date'),
    //     },
    //   ]
    // },
  },
  data() {
      return {
        currentPage: 1,
        perPage: 5,
        addModal: false,
        editModal: false,
        deleteModal: false,
        itemToEdit: null,
        search: {
          jobApplicantStatus: null,
          position: null,
          createdAt: null
        }
      };
    },
  methods: {
    ...mapActions("azJobApplicantsModule",{
      fetchJobApplicants:"FETCH_JOB_APPLICANTS",
    }),
    edit(id) {
      this.$router.push('/job-applicants/'+id)
    },
    filterApplicants() {
      let data = Object.assign({}, this.search)
      if(data.createdAt) {
        data.createdAt = Math.floor(new Date(data.createdAt) / 1000)
      }
      this.fetchJobApplicants(data)
    },
    getStatusColor(status) {
        if(status === 1) {
            return {
              color: 'new',
              status: 'New'
            }
        }
        if(status === 2) {
            return {
              color: 'in-review',
              status: 'In Review'
            }
        }
        if(status === 3) {
            return {
              color: 'contacted',
              status: 'Contacted'
            }
        }
        if(status === 4) {
            return {
              color: 'rejected',
              status: 'Rejected'
            }
        }
        if(status === 5) {
            return {
              color: 'hiring-closed',
              status: 'Hiring Closed'
            }
        }
        return {
          color: '',
          status: 'N/A'
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.filter {
    background: #828282;
    border-radius: 50%;
    padding: 5px 5px 0 5px;
}
.filter i {
    font-size: 22px;
    color: #ffffff;
}
.badge {
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;

    &-pill {
      padding: 3px 15px;
      border-radius: 15px;
    }

    &-new {
      background: #EBA721;
    }

    &-in-review {
      background: #4F57A6;
    }

    &-rejected {
      background: #BD1313;
    }

    &-contacted {
      background: #8D54A2;
    }

    &-hiring-closed {
      background: #B6B8CF;
    }
  }
i {
  color: #dadada;
  font-size: 16px;
}
i:hover {
  cursor: pointer;
}
</style>
